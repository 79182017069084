/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.mr-1 {
	margin-right: 1%;
}

.mr-2 {
	margin-right: 2%;
}

.default-empty-img {
	width: 15rem;
}

.no-records-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.project-card {
	transition: all 100ms ease-in-out;
	color: inherit;
	text-decoration: none;
	width: 18rem;

	&:hover {
		box-shadow: 0px 0px 6px 1px rgba(#8bc34a, 0.6);
	}

	.project-img-container {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 14rem;
	}
}

.label-with-icon {
	display: flex;
	align-items: center;
	column-gap: .3rem;
}

.react-dataTable {
	overflow: visible !important;
}

.dataTable-custom-header {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	box-sizing: border-box;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 100%;
	padding: 4px 16px 4px 24px
}

.placeholder-image {
	object-fit: contain;
}