.fill-to-left {
    position: relative;
    overflow: hidden;
    width: 0;
    -webkit-animation: fill-to-left ease-in-out 0.2s forwards;
    animation: fill-to-left ease-in-out .2s forwards;
}

@-webkit-keyframes fill-to-left {
    100% {
        width: 100px;
    }
}

@keyframes fill-to-left {
    100% {
        width: 100px;
    }
}

.unfill-to-left {
    position: relative;
    overflow: hidden;
    width: 100%;
    -webkit-animation: unfill-to-left ease-in-out 0.2s forwards;
    animation: unfill-to-left ease-in-out  0.2s forwards;
}

@-webkit-keyframes unfill-to-left {
    100% {
        width: 0;
    }
}

@keyframes unfill-to-left {
    100% {
        width: 0;
    }
}

.fill-icon {
    position: relative;
    overflow: hidden;
    width: 0;
    opacity: 0;
    -webkit-animation: fill-icon ease-in-out 0.1s forwards;
    -webkit-animation-delay: 0.2s;
    animation: fill-icon ease-in-out 0.1s forwards;
    animation-delay: 0.2s;
}

@-webkit-keyframes fill-icon {
    100% {
        width: 100%;
        opacity: 1;
    }
}

@keyframes fill-icon {
    100% {
        width: 100%;
        opacity: 1;
    }
}